// auth.js

import { PublicClientApplication} from '@azure/msal-browser';
const msalConfig = {
  auth: {
      clientId: 'f8970b73-4e1a-4c94-84b3-49561df99eec', // Replace with your Azure AD client ID
      // clientId: 'ae03126e-e674-4bc6-8827-bb2a2b8a06bf', // Replace with your Azure AD client ID
      // authority: 'https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2', // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
      authority: 'https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2', // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
      redirectUri: window.location.origin, // The URL where Azure AD will redirect after authentication
  },
  cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const msalInstance = new PublicClientApplication(msalConfig);
const silentRequest = {
  scopes: ['openid', 'profile', 'email','user.read'], // Add any required scopes for your application
  // scopes: ["User.Read","api://f8970b73-4e1a-4c94-84b3-49561df99eec"],
};

msalInstance.handleRedirectPromise()
.then(res=>{
  console.log(res)
  if(res){
    window.location.reload();

  }
})
.catch(err => {
  console.error(err);
});

export const login = async () => {
  try {
    var account=currentUser();
    if(account && account.length>0)
    return account;
    // console.log(account)
    // const loginResponse = await msalInstance.loginPopup();
    const loginResponse = await msalInstance.loginRedirect(silentRequest);
    return loginResponse.account;
  } catch (error) {
    console.error('Error during login:', error);
    return null;
  }
};
export const currentUser=()=>{
  return msalInstance.getAllAccounts();
}

export const getToken = async () => {

  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest);
    return tokenResponse.accessToken;
  } catch (error) {
    // If the silent token acquisition fails, try to acquire a token using popup
    // const tokenRequest = {
    //   scopes: ['openid', 'profile', 'email','user.read'], // Add any required scopes for your application
    // };

    try {
      const tokenResponse = await msalInstance.acquireTokenRedirect(silentRequest);
      return tokenResponse.accessToken;
    } catch (error) {
      console.error('Error during token acquisition:', error);
      return null;
    }
  }
};

export const logout =async () => {
 await msalInstance.logoutRedirect();
};
